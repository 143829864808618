export const commonPrimary = {
    borderRadius: '0.25rem',
    color: 'brand.white80',
    background: 'brand.primaryBlue',
    _disabled: {
        opacity: '0.5',
        // background: 'brand.darkestGray',
        // borderColor: 'brand.white50',
        pointerEvents: 'none',
        cursor: 'not-allowed',
        _hover: {},
        _active: {
            
        },
    },
    _hover: {
        borderColor: 'brand.white50',
        bg: 'brand.primaryBlueHover',
    },
    _active: {
        borderColor: 'brand.white50',
        bg: 'brand.primaryBlueActive',
    }
}

export const modalPrimary = {
    bg: 'brand.modalGray',
    borderColor: 'rgba(255, 255, 255, 0.1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '0.75rem',
}

export const modalPrimaryProps = () => ({
    ...modalPrimary,
});
