import { useActiveAccount, useActiveWalletChain } from 'thirdweb/react';

import { useStore } from '../../tools/zustand';
import { AuthService } from '../../client';

/**
 * Checks if the user is authenticated
 */
export const useIsAuthenticated = () => {
    const activeAccount = useActiveAccount();
    const activeChain = useActiveWalletChain();
    const setIsAuthenticated = useStore((state) => state.updateIsAuthenticated);

    const isLoggedIn = async () => {
        if (!activeAccount?.address || !activeChain?.id) {
            setIsAuthenticated(false);
            return false;
        }

        return await AuthService.isLoggedIn({
            walletId: activeAccount.address,
            chainId: activeChain.id,
        }).then(({ wallet_id, authenticated }) => {
            const isAuth = authenticated && wallet_id === activeAccount.address;
            setIsAuthenticated(isAuth);
            return isAuth;
        });
    };

    return { isLoggedIn };
};
