import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { tabsAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers([...tabsAnatomy.keys]);

const baseStyle = definePartsStyle({
    root: {
        border: 'none',
    },
    tab: {
        color: 'rgba(255, 255, 255, 0.5)',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: '700',
        backgroundColor: 'brand.gray30',
        textTransform: 'uppercase',
        _selected: {
            textDecoration: 'underline',
            color: 'brand.white80',
        },
    },
});

const variants = {
    gameRecords: definePartsStyle({
        root: {
            border: 'none',
        },
        tab: {
            backgroundColor: 'transparent',
            textDecoration: 'none',
        },
    }),
    portraitHomePage: definePartsStyle({
        root: {
            border: 'none',
            borderBottom: 'env(safe-area-inset-bottom)',
            borderTop: '-4px solid rgba(255, 255, 255, 0.1)',
        },
        tab: {
            position: 'relative',
            alignItems: 'center',
            backgroundColor: 'transparent',
            display: 'flex',
            fill: 'brand.accentWhite',
            flexDirection: 'column',
            fontSize: '0.7rem',
            fontStyle: 'normal',
            fontWeight: '400',
            gap: '0.35rem',
            height: '5.5rem',
            minHeight: '4.5rem',
            textAlign: 'center',
            textTransform: 'none',
            width: '6rem',
            _before: {
                content: '""',
                position: 'absolute',
                top: '-3.5px',
                left: 0,
                right: 0,
                height: '3.5px',
                backgroundColor: 'transparent',
                transition: 'background-color 0.3s ease, left 0.3s ease',
            },
            _selected: {
                bg: 'brand.gray70',
                textDecoration: 'none',
                color: 'brand.textWhite',
                _before: {
                    backgroundColor: '#3181CE',
                    left: 0,
                },
                borderRadius: '1px',
                boxShadow: '0 -6px 12px -5px #3181CE',
            },
        },
        tablist: {
            border: 'none',
            backgroundColor: 'brand.gray70',
            borderRadius: '100px',
            gap: '0.25rem',
        },
    }),
    portraitGameRecords: definePartsStyle({
        root: {
            border: 'none',
        },
        tab: {
            alignItems: 'center',
            backgroundColor: 'brand.gray40',
            color: 'rgba(255, 255, 255, 0.5)',
            fill: 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '700',
            textAlign: 'center',
            textTransform: 'none',
            minHeight: '3.5rem',
            height: '3.5rem',
            gap: '0.15rem',
            width: '10rem',
            borderRadius: '0.5rem',
            _selected: {
                textDecoration: 'none',
                color: 'brand.accentWhite',
                fill: 'brand.accentWhite',
                borderBottom: '0.25rem solid',
                borderColor: 'brand.primaryBlue',
            },
        },
        tablist: {
            backgroundColor: 'transparent',
            border: 'none',
            borderBottom: '0px',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'center',
        },
    }),
    portraitProfilePage: definePartsStyle({
        root: {
            border: 'none',
        },
        tab: {
            alignItems: 'center',
            backgroundColor: 'brand.gray40',
            color: 'rgba(255, 255, 255, 0.5)',
            fill: 'rgba(255, 255, 255, 0.5)',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '700',
            textAlign: 'center',
            textTransform: 'none',
            minHeight: '3.5rem',
            height: '3.5rem',
            gap: '0.15rem',
            width: '10rem',
            borderRadius: '0.5rem',
            _selected: {
                textDecoration: 'none',
                color: 'brand.accentWhite',
                fill: 'brand.accentWhite',
                borderBottom: '0.25rem solid',
                borderColor: 'brand.primaryBlue',
            },
        },
        tablist: {
            backgroundColor: 'transparent',
            border: 'none',
            borderBottom: '0px',
            display: 'flex',
            gap: '1rem',
            justifyContent: 'center',
        },
    }),
    drawer: definePartsStyle({
        root: {
            border: 'none',
            _selected: {
                textDecoration: 'none',
                color: 'brand.secondaryBlueActive',
            },
        },
        tab: {
            alignItems: 'center',
            backgroundColor: 'transparent',
            color: 'brand.accentWhite',
            fill: 'brand.accentWhite',
            display: 'flex',
            flexDirection: 'column',
            fontSize: '0.7rem',
            fontStyle: 'normal',
            fontWeight: '400',
            textAlign: 'center',
            textTransform: 'uppercase',
            minHeight: '3.5rem',
            height: '3.5rem',
            gap: '0.15rem',
            _selected: {
                textDecoration: 'none',
                color: 'brand.secondaryBlueActive',
                fill: 'brand.secondaryBlueActive',
            },
        },
        tablist: {
            border: 'none',
            backgroundColor: 'transparent',
            borderBottom: '0px',
        },
        tabpanel: {
            border: 'none',
            backgroundColor: 'transparent',
        },
    }),
    settings: definePartsStyle({
        root: {
            border: 'none',
        },
        tab: {
            justifyContent: 'flex-start',
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '700',
            minWidth: '5.5rem',
            bg: 'transparent',
            textAlign: 'left',
            alignItems: 'start',
        },
    }),
    chat: definePartsStyle({
        root: {
            border: 'none',
        },
        tab: {
            color: 'rgba(255, 255, 255, 0.5)',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '700',
            minWidth: '5.5rem',
            _selected: {
                backgroundColor: 'brand.lightGray',
                borderBottom: 'none',
                borderTop: '0.125rem solid',
                color: 'brand.primaryBlue',
                textDecoration: 'none',
            },
            _hover: {
                backgroundColor: 'brand.lightGray',
                color: 'brand.primaryBlue',
                borderBottom: 'none',
            },
            _focus: {
                boxShadow: 'none',
                borderBottom: 'none',
            },
            _active: {
                backgroundColor: 'brand.lightGray',
                borderBottom: 'none',
            },
        },
        tablist: {
            border: 'none',
            backgroundColor: 'transparent',
            borderBottom: '0px',
        },
        tabpanel: {
            border: 'none',
            backgroundColor: 'brand.lightGray',
        },
    }),
    profile: definePartsStyle({
        root: {
            border: 'none',
        },
        tab: {
            color: 'rgba(255, 255, 255, 0.5)',
            bg: 'transparent',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '700',
            minWidth: '6.25rem',
            display: 'flex',
            width: '6.8rem',
            height: '2.2rem',
            padding: '0.8125rem 0.875rem',
            justifyContent: 'center',
            alignItems: 'center',
            _selected: {
                borderRadius: '3.125rem',
                border: '0.125rem solid',
                borderColor: 'brand.accentWhite',
                background: 'brand.primaryGray',
            },
            _hover: {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
            },
            _focus: {
                boxShadow: 'none',
            },
            _active: {
                backgroundColor: 'transparent',
            },
        },
        tablist: {
            border: 'none',
            backgroundColor: 'transparent',
        },
        tabpanel: {
            border: 'none',
            backgroundColor: 'transparent',
        },
    }),
};

export const Tabs = defineMultiStyleConfig({ baseStyle, variants });
