export const Slider = {
    baseStyle: {
        track: {
            bg: 'brand.lightestGray',
        },
        filledTrack: {
            bg: 'brand.primaryBlue',
        },
        thumb: {
            bg: 'brand.primaryBlue',
            boxSize: '1.75rem',
        },
    },
};
export default Slider;
