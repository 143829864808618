import { useEffect } from 'react';
import { useStore } from '../../tools/zustand';

export const useDevicePropertiesEffect = () => {
    const updateDeviceProperties = useStore((state) => state.updateDeviceProperties);

    useEffect(() => {
        if (typeof window === 'undefined') return;

        const updateProperties = () => {
            const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );
            const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
            const hasPhysicalKeyboard = !isMobile && !isTouchDevice;
            const isPortrait = window.innerHeight > window.innerWidth;

            updateDeviceProperties({
                isPortrait,
                isTouchDevice,
                hasPhysicalKeyboard,
            });
        };

        updateProperties();
        window.addEventListener('resize', updateProperties);
        return () => window.removeEventListener('resize', updateProperties);
    }, [updateDeviceProperties]);
};

export default useDevicePropertiesEffect;
