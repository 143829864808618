import { useState, useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { Box, Spinner, Text } from '@chakra-ui/react';
import { AppShell } from '../components/AppShell';
import { useDevicePropertiesEffect } from '../components/hooks/useDevicePropertiesEffect';
import '../styles/CardFlip.css';
import '../styles/globals.css';
function MyApp({
  Component,
  pageProps
}: AppProps) {
  const [isClient, setIsClient] = useState(false);
  useDevicePropertiesEffect();
  useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) {
    return <Box height="100vh" width="100vw" display="flex" alignItems="center" justifyContent="center" flexDirection="column" bg="brand.primaryGray">
                <Spinner size="xl" color="brand.primary" mb={4} />
                <Text fontSize="xl" color="white">
                    Loading...
                </Text>
            </Box>;
  }
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
                <title>CCP Gaming</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover, maximum-scale=1.0" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            </Head>
            <AppShell data-sentry-element="AppShell" data-sentry-source-file="_app.tsx">
                <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
            </AppShell>
        </>;
}
export default MyApp;