export const Text = {
    baseStyle: {
        color: 'white',
        margin: 0,
        padding: 0,
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    sizes: {
        sm: {
            fontSize: '.75rem',
        },
        md: {
            fontSize: '1rem',
        },
        lg: {
            fontSize: '1.5rem',
        },
        xl: {
            fontSize: '2rem',
        },
    },
    variants: {
        bold: {
            fontWeight: '700',
        },
        actionInfoText: {
            color: 'brand.white50',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            textAlign: 'center',
        },
        footerText: {
            color: '#FFF',
            fontSize: '1rem',
            fontStyle: 'italic',
            fontWeight: '700',
            lineHeight: 'normal',
            letterSpacing: '0.32px',
            textTransform: 'uppercase',
        },
        menuItem: {
            fontSize: '1rem',
            textAlign: 'start',
            fontWeight: 'bold',
            whiteSpace: 'normal',
        },
        modalTitle: {
            textAlign: 'start',
            fontSize: '20px',
            whiteSpace: 'normal',
        },
        modalHeader: {
            fontSize: '1rem',
        },
        modalH1: {
            fontWeight: '700',
            fontSize: '1.125rem',
            whiteSpace: 'normal',
        },
        modalH2: {
            fontWeight: '700',
            fontSize: '0.875rem',
            textAlign: 'start',
            whiteSpace: 'normal',
        },
        modalPopupHeader: {
            color: '#FFF',
            fontSize: '1.75rem',
            fontStyle: 'italic',
            fontWeight: '600',
            lineHeight: 'normal',
            letterSpacing: '-0.64px',
            whiteSpace: 'normal',
            textAlign: 'start',
        },
        modalPopup: {
            color: '#FFF',
            fontSize: '1.25rem',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            letterSpacing: '-0.52px',
            whiteSpace: 'normal',
            textAlign: 'start',
        },
        sliderText: {
            color: 'rgba(255, 255, 255, 0.50)',
            fontSize: '.75rem',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
        },
        modalBodyHeader: {
            color: 'white',
            fontSize: '2rem',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            whiteSpace: 'normal',
        },
        modalBodyText: {
            color: 'rgba(255, 255, 255, 0.90)',
            textAlign: 'center',
            fontSize: '1.25rem',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            whiteSpace: 'normal',
        },
        modalSubheader: {
            fontSize: '0.875rem',
            fontStyle: 'italic',
            fontWeight: '300',
            lineHeight: 'normal',
            letterSpacing: '0.0.875rem',
            color: 'rgba(255, 255, 255, 0.90)',
            whiteSpace: 'normal',
        },
        moneyText: {
            color: 'white',
            textAlign: 'center',
            leadingTrim: 'both',
            textEdge: 'cap',
            fontFamily: 'Roboto Mono',
            fontSize: '1.5rem',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
        },
        tableText: {
            fontSize: '0.9375rem',
            fontWeight: '500',
            textAlign: 'left',
            fontStyle: 'normal',
        },
        tableSortByButtonText: {
            textAlign: 'left',
            color: 'brand.textWhite',
            fontSize: '.75rem',
            fontStyle: 'normal',
            fontWeight: '300',
            lineHeight: 'normal',
            textTransform: 'uppercase',
        },
        mobileTableSortByButtonText: {
            textAlign: 'left',
            color: 'brand.textWhite',
            fontSize: '.5rem',
            fontStyle: 'normal',
            fontWeight: '300',
            lineHeight: 'normal',
            textTransform: 'uppercase',
        },

        mobileTableSortOptionText: {
            fontSize: '0.7rem',
            fontWeight: '600',
            color: 'brand.textWhite',
            fontStyle: 'normal',
            lineHeight: 'normal',
        },
        tableSortOptionText: {
            fontSize: '0.875rem',
            fontWeight: '600',
            color: 'brand.textWhite',
            fontStyle: 'normal',
            lineHeight: 'normal',
        },
        tableHeaderText: {
            color: 'brand.textWhite',
            opacity: '0.6',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: 'normal',
            textTransform: 'uppercase',
            textAlign: 'left',
        },
        warningText: {
            color: 'brand.secondaryOrange',
            textAlign: 'center',
            fontSize: '1rem',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: 'normal',
            whiteSpace: 'normal',
            maxWidth: '500px',
        },
        mobilePlayerManagementUsername: {
            fontSize: '1rem',
            fontWeight: '700',
            textDecoration: 'underline',
            textAlign: 'left',
        },
        mobilePlayerManagementValue: {
            fontSize: '0.875rem',
            fontWeight: '600',
            textAlign: 'left',
        },
    },
};

export default Text;
